<template>
  <div  align="center" justify="center">

    <div class="text-center pa-4" >
      <v-dialog
        v-model="dialog"
        width="auto"
      >
        <v-card
          class="text-pre-wrap border-xl border-surface"
          max-width="400"
          :text=this.errorText
          :title=this.errorTitle
        >
          <template v-slot:actions>
            <v-btn
              rounded
              class="myclass ms-auto"
              color="black"
              variant="outlined"
              text="Ok"
              @click="dialog = false"
            ></v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>
        
          <v-card class="myclass pa-6 ma-6" color=black variant="outlined" elevation="8" max-width="800" rounded="lg">
            <v-card-text>
                <v-sheet>
                <v-form id="registerForm" fast-fail @submit.prevent="onSignup">

                      <vue-tel-input v-model="phoneNumber" 
                        placeholder="Enter your phone number" 
                        mode="international" 
                        :inputOptions="options" 
                        :preferredCountries="country">
                      </vue-tel-input>

                      <div class="pa-3"/>

                      <v-text-field
                        name="username"
                        label="Username"
                        id="username"
                        v-model="username"
                        :rules="usernameRules"
                        density="compact"
                        required></v-text-field>

                      <v-text-field
                        name="email"
                        label="E-mail"
                        id="email"
                        v-model="email"
                        type="email"
                        :rules="emailRules"
                        density="compact"
                        required></v-text-field>
                    
                      <v-text-field
                        name="password"
                        label="Password"
                        id="password"
                        v-model="password"
                        :append-inner-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'" 
                        :type="isVisible ? 'text' : 'password'"
                        @click:append-inner="isVisible = !isVisible"
                        density="compact"
                        required></v-text-field>
                    
                      <v-text-field
                        name="confirmPassword"
                        label="Confirm Password"
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :append-inner-icon="isVisible ? 'mdi-eye-off' : 'mdi-eye'" 
                        :type="isVisible ? 'text' : 'password'"
                        @click:append-inner="isVisible = !isVisible"
                        density="compact"
                        :rules="[comparePasswords]"></v-text-field>
                    

                    
                      <v-btn class="myclass" rounded color="black" variant="outlined" name="button" type="submit" :disabled="isDisable" >Sign up</v-btn>
                   

                </v-form>
                </v-sheet>
            </v-card-text>
            <v-card-text class="text-center">
              <router-link to="/login">
                  <a class="text-blue text-decoration-none" rel="noopener noreferrer" target="_blank"></a>
                  Already a member?
              </router-link>
            </v-card-text>
          </v-card>
       

    </div>
</template>
  
<script>
import { VueTelInput } from 'vue-tel-input';
import { auth } from '../firebaseConfig'
import 'vue-tel-input/vue-tel-input.css';
import error_code from '@/misc/error_code';

export default {
  components:{
     VueTelInput,
    },
    data () {
      return {
          dialog: false,
          errorText: "Unexpected error on register",
          errorTitle: "Unable to register account",
          email: '',
          password: '',
          confirmPassword: '',
          username: '',
          phoneNumber: '',
          isVisible: false,
          emailRules: [
          v => { 
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) return true 
            return 'E-mail must be valid'
            },
          ],
          usernameRules: [
          v => {
            if (/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v)) return true
            return 'Username must be 8-20 characters long and contains no illegal symbol'
            },
          ],
          options:{
            showDialCode: true,
            required: true
          },
          country: ['MY']
      }
    },
    beforeCreate(){
      auth.onAuthStateChanged(user => {
              if (user)
                  this.$router.push('/timetable')
        })
      },
    computed: {
      comparePasswords () {
          return this.password !== this.confirmPassword ? 'Passwords do not match' : ''
      },
      isDisable(){
        return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) || !(/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(this.username)) || !(this.password == this.confirmPassword) || !(this.password!='')
      },
      user () {
          return this.$store.getters.user
      }
    },
    watch: {
      user (value) {
          if (value !== null && value !== undefined) {
          this.$router.push('/timetable')
          }
      }
    },
    methods: {
      async onSignup (event) {
          console.log(event)
          const response = await this.$store.dispatch('register', {email: this.email, password: this.password, username: this.username, phoneNumber: this.phoneNumber})
          if (response == 200){
            this.$router.push('/')
          }
          else {
            this.dialog = true
            this.errorText = error_code[response] + "\n\n\n" +'Error Code: '+ response
          }
    }
  }
}
</script>