<template >
    <!-- <nav >
      <v-row align="center" justify="center">
        <v-img :width="600" aspect-ratio="16/3" cover :src="bannerImage" />
      </v-row>
    </nav> -->
    <BottomNavigation v-if="isLogin"/>
    <router-view/>
  <!--Error Dialog here -->
  <!-- <div class="text-center pa-4" >
    <v-btn @click="dialog = true">
      Open Dialog
    </v-btn>

    <v-dialog
      v-model="dialog"
      width="auto"
      @create-user-error="!dialog"
    >
      <v-card
        max-width="400"
        :text=this.errorText
        :title=this.errorTitle
      >
        <template v-slot:actions>
          <v-btn
            class="ms-auto"
            text="Ok"
            @click="dialog = false"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div> -->
  
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation.vue';
import { auth } from './firebaseConfig'

export default{
  components:{
    BottomNavigation,
  },
  beforeMount(){
    // to check user if login on firebase, no the route to login
    auth.onAuthStateChanged(user => {
            if (user){
              this.isLogin = true
            }
            else
              this.isLogin = false
            });
    },
  data(){
    return {
      isLogin: false,
      dialog: false,
      errorText: "Login falied",
      errorTitle: "Login not successful",
    }
  }
}
</script>

<style>
#app {
  font-family: "Comic Sans MS", "Comic Sans", "Brush Script MT";
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2b3846;
}

nav {
  margin-top: 0%;
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>
